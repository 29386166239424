.jam-section{
    background-color: var(--background-color-accent);
    padding: 100px;
}

.jam-title{
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color);
}

@media (max-width: 480px) {
    .jam-section {
        padding: 10px;
    }
}
