/* Overall Section */
.about-me-section {
    background: linear-gradient(135deg, var(--background-color-accent) 30%, var(--highlight-color-one) 100%);
    text-align: center;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

/* Paragraph / Intro Section */
.paragraph-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 90%;
    max-width: 900px;
}

.about-me-title {
    color: var(--text-color);
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.paragraph-image {
    height: 250px;
    width: 250px;
    object-fit: contain;
}

.paragraph-text {
    padding: 20px;
    background-color: var(--background-color-accent);
    color: var(--text-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    line-height: 1.6;
    font-size: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.paragraph-text:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Reset default ul styles */
.about-me-bullets {
    list-style: none;
    padding: 0;
    margin: 0;
}

.about-me-bullets li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}

/* Custom bullet styling */
.about-me-bullets li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--accent-color-one, #3498db); /* Use your accent color */
    font-size: 1.2rem;
    line-height: 1;
}

/* CTA Button */
.cta-button {
    background-color: var(--highlight-color-two);
    color: var(--text-color);
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.cta-button:hover {
    background-color: var(--accent-color-two);
}

/* Tech Section */
.tech-title {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 600;
    margin-top: 60px;
}

.tech-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; /* wrap for responsiveness */
    gap: 40px;
    margin-top: 40px;
}

.card-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tech Card */
.tech-card {
    position: relative;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: bounce 3s infinite;
}

.tech-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
}

.tech-card:active {
    scale: 1.1;
}

.card-section:nth-child(5) .tech-card {
    animation-delay: 0s;
}
.card-section:nth-child(4) .tech-card {
    animation-delay: 0.2s;
}
.card-section:nth-child(3) .tech-card {
    animation-delay: 0.4s;
}
.card-section:nth-child(2) .tech-card {
    animation-delay: 0.6s;
}
.card-section:nth-child(1) .tech-card {
    animation-delay: 0.8s;
}
.card-section:nth-child(6) .tech-card {
    animation-delay: 0.10s;
}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-25px);
    }
    60% {
        transform: translateY(-15px);
    }
}


/* Tech Name */
.tech-name {
    position: relative;
    margin-top: 10px;
    width: 75px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.tech-name:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.tech-logo {
    width: 50px;
    height: 50px;
}

.name {
    color: var(--text-color);
    font-size: 0.9rem;
    font-weight: 500;
}



/* Media Queries for Responsiveness */
@media (max-width: 860px) {
    .paragraph-text {
        width: 75%;
    }

    .about-me-section {
        padding-top: 100px;
    }

    .tech-section {
        gap: 20px;
    }

}
